<template>
    <div class="icon-box text-center">
        <span class="icon icon-sm icon-circle icon-primary icon-filled"><i :class="icon"></i></span>
        <span class="title">{{ title }}</span>
    </div>
</template>

<script>
    export default {
        name: 'IconBox',
        props: {
            icon: String,
            title: String
        }
    }
</script>

<style>

</style>
