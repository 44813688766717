<template>
    <div class="resume-box">
        <span class="icon animated" data-animation="fadeInDown"><i class="ti-comment-alt text-tertiary"></i></span>
        <h4><strong>About</strong> Me</h4>
        <p class="lead">{{ about.lead }}</p>
        <p>{{ about.content }}</p>
    </div>
</template>

<script>
    import aboutData from '../assets/about.json'

    export default {
        name: 'BoxAbout',
        data() {
            return {
                about: aboutData
            }
        }
    }
</script>

<style>

</style>
