<template>
    <div :class="`${filter} masonry-item margin-b-30 col-lg-4 col-sm-6 col-xs-12`">
        <div class="gallery-item">
            <div class="item-photo">
                <img :src="image" :alt="title" />
                <div class="item-hover bg-dark dark">
                    <div class="item-hover-content">
                        <a :href="link" target="_blank" class="icon icon-sm icon-circle icon-white icon-hover"><i class="fa fa-play"></i></a>
                    </div>
                </div>
            </div>
            <div class="item-title">
                <span class="title">{{ title }}</span>
                <span class="caption text-muted">{{ description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PortifolioItem',
        props: {
            title: String,
            description: String,
            image: String,
            link: String,
            filter: String
        }
    }
</script>

<style>

</style>
