<template>
<div class="resume-box">
    <span class="icon animated" data-animation="fadeInDown"><i class="ti-calendar text-tertiary"></i></span>
    <h4><strong>Education</strong> &amp; jobs</h4>

    <div class="timeline">
        <SingleEvent v-for="ej in educationJobs" :key="ej.date" :date="ej.date" :name="ej.name">{{ ej.location }}</SingleEvent>
    </div>
</div>
</template>

<script>
    import educationJobsData from '../assets/education-jobs.json'
    import SingleEvent from './SingleEvent.vue'

    export default {
        name: 'BoxEducationJobs',
        data() {
            return {
                educationJobs: educationJobsData
            }
        },
        components: {
            SingleEvent
        }
    }
</script>

<style>

</style>
