<template>
    <section id="testimonials" class="section bg-primary dark padding-v-60">
        <div class="container container-wide">
            <h6 class="margin-b-50">Testimonials</h6>

            <h1 class="margin-b-0">More than <strong>15</strong> years of experience - <strong>over 1k</strong> happy clients!</h1>
            <h5 class="text-muted margin-b-60">&nbsp;</h5>
            
            <div class="row padding-lg">
                <Testimonial v-for="testimonial in testimonials" :key="testimonial.name" :name="testimonial.name" :position="testimonial.position" :photo="testimonial.photo" :content="testimonial.content" />
            </div>
        </div>
    </section>
</template>

<script>
    import testimonialsData from '../assets/testimonials.json'
    import Testimonial from './Testimonial.vue'

    export default {
        name: 'SectionTestimonials',
        components: {
            Testimonial
        },
        data() {
            return {
                testimonials: testimonialsData
            }
        }
    }
</script>

<style>

</style>
