<template>
    <div class="skill">
        <div class="progress progress-animated">
            <div class="progress-bar progress-bar-primary" role="progressbar" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100">
                <span></span>
            </div>
        </div>
        <strong>{{ name }}</strong>
    </div>
</template>

<script>
    export default {
        name: 'Skill',
        props: {
            name: String,
            value: String
        }
    }
</script>

<style>

</style>
