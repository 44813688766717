<template>
<div class="resume-box">
    <span class="icon animated" data-animation="fadeInDown"><i class="ti-mouse-alt text-tertiary"></i></span>
    <h4>My <strong>Specialities</strong></h4>

    <Specialitie v-for="specialitie in specialities" :key="specialitie.name" :icon="specialitie.icon" :name="specialitie.name" :description="specialitie.description" />
</div>
</template>

<script>
    import specialitiesData from '../assets/specialities.json'
    import Specialitie from './Specialitie.vue';

    export default {
        name: 'BoxSpecialities',
        data() {
            return {
                specialities: specialitiesData
            }
        },
        components: {
            Specialitie
        }
    }
</script>

<style>

</style>
