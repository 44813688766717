<template>
    <div class="speciality">
        <span class="speciality-icon"><i :class="`text-muted-2x ${icon}`"></i></span>
        <div class="speciality-content">
            <h5 class="margin-b-0">{{ name }}</h5>
            <p class="speciaity-description">{{ description }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Specialitie',
        props: {
            icon: String,
            name: String,
            description: String
        }
    }
</script>

<style>

</style>
