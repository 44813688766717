<template>
    <div>
        <a href="#" id="mobile-nav-toggle" class="icon icon-circle icon-sm icon-primary icon-hover" data-target="mobile-nav"><i class="ti-menu"></i></a>
        <nav id="mobile-nav" class="bg-white">
            <div class="mobile-nav-wrapper">
                <p>&nbsp;</p>

                <ul class="nav nav-vertical">
                    <li><a href="#start"><span>Start</span></a></li>
                    <li><a href="#resume"><span>Resume</span></a></li>
                    <li><a href="#testimonials"><span>Testimonials</span></a></li>
                    <li><a href="#portfolio"><span>Portfolio</span></a></li>
                </ul>

                <div class="margin-t-20">
                    <strong class="text-sm">Check my social media!</strong>
                    <ul class="list-inline margin-t-10">
                        <li><a href="https://www.linkedin.com/in/raphaelmedeiros" class="icon icon-xs"><i class="fa fa-linkedin text-muted"></i></a></li>
                        <li><a href="https://github.com/byraphaelmedeiros" class="icon icon-xs"><i class="fa fa-github text-muted"></i></a></li>
                    </ul>
                </div>
            </div>
            <a href="#" class="mobile-nav-close icon icon-hover icon-xs icon-circle icon-primary" data-target="mobile-nav"><i class="fa fa-times"></i></a>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Navigation'
    }
</script>

<style>

</style>
