<template>
    <div class="resume-box">
        <span class="icon animated" data-animation="fadeInDown"><i class="ti-infinite text-tertiary"></i></span>
        <h4><strong>Hobbies</strong> &amp; Interests</h4>
            <ul class="list-inline">
            <li v-for="hobbie in hobbies" :key="hobbie.title">
                <IconBox :icon="hobbie.icon" :title="hobbie.title" />
            </li> 
        </ul>
    </div>
</template>

<script>
    import hobbiesData from '../assets/hobbies.json'
    import IconBox from './IconBox.vue'

    export default {
        name: 'BoxHobbies',
        data() {
            return {
                hobbies: hobbiesData
            }
        },
        components: {
            IconBox
        }
    }
</script>

<style>

</style>
