<template>
    <div class="resume-box">
        <span class="icon animated" data-animation="fadeInDown"><i class="ti-flag-alt text-tertiary"></i></span>
        <h4><strong>Language</strong> skills</h4>
        
        <Skill v-for="language in languages" :key="language.name" :name="language.name" :value="language.value" />
    </div>
</template>

<script>
    import languagesData from '../assets/languages.json'
    import Skill from './Skill.vue'

    export default {
        name: 'BoxLanguages',
        data() {
            return {
                languages: languagesData
            }
        },
        components: {
            Skill
        }
    }
</script>

<style>

</style>
