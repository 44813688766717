<template>
    <div id="app">
        <div id="content" class="bg-white">
            <SectionStart />

            <SectionResume />

            <SectionTestimonials />

            <SectionPortfolio />
        </div>

        <Navigation />
    </div>
</template>

<script>
    import SectionStart from './components/SectionStart.vue'
    import SectionResume from './components/SectionResume.vue'
    import SectionTestimonials from './components/SectionTestimonials.vue'
    import SectionPortfolio from './components/SectionPortfolio.vue'
    import Navigation from './components/Navigation.vue'

    export default {
        components: {
            SectionStart,
            SectionResume,
            SectionTestimonials,
            SectionPortfolio,
            Navigation
        }
    }
</script>

<style>

</style>
