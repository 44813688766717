<template>
    <section id="start" class="section fullheight bg-secondary dark padding-v-60">
        <div class="bg-image animated infinite zooming"><img src="/assets/img/photos/it-bg01.jpg" alt="..."></div>
        
        <div class="container container-wide v-bottom padding-v-80">
            <img src="/assets/img/photos/me-96x96.png" alt="..." class="img-circle margin-b-30">
            <h1 class="text-lg margin-b-0">Hi! I’m <strong>Raphael Medeiros</strong></h1>
            <h5 class="text-tertiary margin-b-40">experienced Software Engineer / Backend Developer</h5>
            <span data-target="local-scroll"><a href="#resume" class="btn btn-lg btn-primary"><span>Go to my resume!</span><i class="ti-arrow-down"></i></a></span>
            <a href="/download/2023__[CV-RESUME]_Raphael_Medeiros_(ENGLISH_VERSION).pdf" target="_blank" class="btn btn-link"><span>Download my CV</span><i class="ti-file"></i></a>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'SectionStart'
    }
</script>

<style>

</style>
