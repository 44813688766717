<template>
    <section id="portfolio" class="section bg-grey padding-v-60">
        <div class="container container-wide">
            <h6 class="margin-b-50">Portfolio</h6>

            <nav class="text-center">
                <ul class="nav nav-filter nav-pills margin-b-40" data-filter-grid="#portfolio-list">
                    <li class="active"><a href="#" data-filter="*">All</a></li>
                    <li><a href="#" data-filter=".web">Web</a></li>
                    <li><a href="#" data-filter=".backend">Backend</a></li>
                    <li><a href="#" data-filter=".other">Other</a></li>
                </ul>
            </nav>

            <div id="portfolio-list" class="row masonry">
                <div class="masonry-sizer col-lg-4 col-sm-6 col-xs-12"></div>

                <PortfolioItem v-for="portfolio in portfolioItems" :key="portfolio.title" 
                    :title="portfolio.title" 
                    :image="portfolio.image" 
                    :link="portfolio.link" 
                    :description="portfolio.description" 
                    :filter="portfolio.filter" />
            </div>
        </div>
    </section>
</template>

<script>
    import portfolioData from '../assets/portfolio.json'
    import PortfolioItem from './PortfolioItem.vue'

    export default {
        name: 'SectionPortfolio',
        data() {
            return {
                portfolioItems: portfolioData
            }
        },
        components: {
            PortfolioItem
        }
    }
</script>

<style>

</style>
