<template>
    <div class="resume-box">
        <span class="icon animated" data-animation="fadeInDown"><i class="ti-cup text-tertiary"></i></span>
        <h4><strong>Skills</strong> &amp; abilities</h4>
        
        <Skill v-for="skill in skills" :name="skill.name" :value="skill.value" :key="skill.name" />
    </div>
</template>

<script>
    import skillsData from '../assets/skills.json'
    import Skill from './Skill.vue';

    export default {
        name: 'BoxSkills',
        data() {
            return {
                skills: skillsData
            }
        },
        components: {
            Skill
        }
    }
</script>

<style>

</style>
