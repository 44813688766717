<template>
    <section id="resume" class="section padding-v-60">
        <div class="container container-wide">
            <h6 class="margin-b-50">Resume</h6>

            <div class="row padding-lg">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <BoxAbout />
                    <BoxSkills />
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <BoxSpecialities />
                    <BoxEducationJobs />
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <BoxLanguages />
                    <BoxHobbies />
                    <BoxPatents />
                </div>
            </div>     
        </div>
    </section>
</template>

<script>
    import BoxAbout from './BoxAbout.vue'
    import BoxSkills from './BoxSkills.vue'
    import BoxSpecialities from './BoxSpecialities.vue'
    import BoxEducationJobs from './BoxEducationJobs.vue'
    import BoxLanguages from './BoxLanguages.vue'
    import BoxHobbies from './BoxHobbies.vue'
    import BoxPatents from './BoxPatents.vue'

    export default {
        name: 'SectionResume',
        components: {
            BoxAbout,
            BoxSkills,
            BoxSpecialities,
            BoxEducationJobs,
            BoxLanguages,
            BoxHobbies,
            BoxPatents
        }
    }
</script>

<style>

</style>
