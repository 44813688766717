<template>
    <div class="timeline-event te-primary">
        <span class="event-date">{{ date }}</span>
        <span class="event-name">{{ name }}</span>
        <span class="event-description">
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'SingleEvent',
        props: {
            date: String,
            name: String
        }
    }
</script>

<style>

</style>
