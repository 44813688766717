<template>
    <div class="resume-box">
        <span class="icon animated" data-animation="fadeInDown"><i class="ti-lock text-tertiary"></i></span>
        <h4><strong>Software</strong> patents</h4>
        <div class="timeline">
            <SingleEvent v-for="patent in patents" :key="patent.name" :date="patent.date" :name="patent.name">
                <span v-html="patent.content"></span>
            </SingleEvent>
        </div>
    </div>
</template>

<script>
    import patentsData from '../assets/patents.json'
    import SingleEvent from './SingleEvent.vue'

    export default {
        name: 'BoxPatents',
        data() {
            return {
                patents: patentsData
            }
        },
        components: {
            SingleEvent
        }
    }
</script>

<style>

</style>
