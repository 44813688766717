<template>
    <div class="col-lg-4 col-sm-6 col-xs-12">
        <div class="testimonial testimonial-dark">
            <div class="testimonial-content font-alt">
                {{ content }}
            </div>
            <div class="testimonial-author testimonial-with-photo">
                <div class="testimonial-photo animated" data-animation="zoomIn"><img class="img-circle" :src="photo" :alt="name" /></div>
                <div class="testimonial-author-txt">
                    <span class="name">{{ name }}</span>
                    <span class="caption text-secondary">{{ position }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Testimonial',
        props: {
            name: String,
            position: String,
            photo: String,
            content: String
        }
    }
</script>

<style>

</style>
